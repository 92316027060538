.video-section {
    padding: 50px;
  }
  
  .video-wrapper {
    position: relative;
    padding-bottom: 56.25%; /* Proporción del video (9/16) */
    height: 0;
    overflow: hidden;
  }
  
  .video-wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .texto-section {
    background-color: #f8f9fa; /* Color de fondo del lado derecho */
    padding: 50px;
    display: flex;
    align-items: center;
  }
  
  .contenido {
    max-width: 600px; /* Ajusta según sea necesario */
    margin: 0 auto;
  }
  
  /* Puedes agregar estilos adicionales según tus necesidades */
  