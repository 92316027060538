/* Preguntas.css */
.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: blue; /* Color por defecto */
  color: white;
  z-index: 1000; /* Asegura que la barra esté encima de otros elementos */
}

/* Barra.css */
/* Resto del CSS... */

/* Cambios para la página de inicio */
.navbar.home-page {
  background-color: green; /* Color para la página de inicio */
}

/* Cambios para otras páginas */
.navbar.other-page {
  background-color: red; /* Color para otras páginas */
}

  .logo {
    font-size: 24px;
  }
  
  .menu-btn {
    display: none; /* Ocultar el botón de menú en tamaños grandes */
  }
  
  .menu {
    display: flex;
    align-items: center;
  }
  
  .nav-btn {
    margin-left: 10px;
    padding: 8px;
    border: none;
    background: none;
    color: white;
    cursor: pointer;
  }
  
  @media screen and (max-width: 768px) {
    .menu-btn {
      display: block;
      font-size: 20px;
      margin-right: 10px;
      cursor: pointer;
    }
  
    .menu {
      flex-direction: column;
      position: absolute;
      top: 60px;
      right: 0;
      background-color: #333;
      padding: 10px;
      display: none;
    }
  
    .menu.open {
      display: flex;
    }
  }
  