.footer {
    background-color: #343a40; /* Color de fondo del pie de página */
    padding: 50px 0; /* Espaciado interno superior e inferior */
    color: #ffffff; /* Color del texto */
  }
  
  .footer-section {
    margin-bottom: 30px; /* Espaciado inferior entre secciones */
  }
  
  .footer-section img {
    max-width: 100%;
  }
  
  .footer-bottom {
    background-color: #212529; /* Color de fondo de la sección de derechos de autor */
    padding: 10px 0; /* Espaciado interno superior e inferior de la sección de derechos de autor */
  }
  
  .footer-bottom p {
    margin: 0; /* Elimina el margen por defecto */
  }
  
  /* Ajusta los estilos según tus preferencias y requisitos específicos */
  