.comentario-card {
    width: 18rem;
    margin: 10px;
    border-radius: 15px;
    overflow: hidden;
  }
  


  .estrellas {
    color: #f39c12;
  }
  
  .estrella {
    font-size: 20px;
  }
  